<template>
    <div class="row">
        <div class="col-md-6">
            <!-- Inicio Tipo sistema -->
            <compania-facturacion-opciones v-bind="$props" /> 
            <!-- Fin Tipo sistema -->
            <!-- Inicio Costes medios -->
            <compania-facturacion-costes v-bind="$props" />
            <!-- Fin Costes medios -->  
        </div>
        <div class="col-md-6">
            <!-- Inicio Subcuentas -->
            <compania-facturacion-subcuentas v-bind="$props" />
            <!-- Fin Subcuentas -->
            <!-- Inicio Por defecto -->
            <compania-facturacion-defecto v-bind="$props" />
            <!-- Fin Por defecto -->
        </div>
    </div>
</template>

<script>
import CompaniaFacturacionOpciones from './secciones/CompaniaFacturacionOpciones.vue'
import CompaniaFacturacionCostes from './secciones/CompaniaFacturacionCostes.vue'
import CompaniaFacturacionSubcuentas from './secciones/CompaniaFacturacionSubcuentas.vue'
import CompaniaFacturacionDefecto from './secciones/CompaniaFacturacionDefecto.vue'

export default {
    components: {
        CompaniaFacturacionOpciones,
        CompaniaFacturacionCostes,
        CompaniaFacturacionSubcuentas,
        CompaniaFacturacionDefecto,
    },
    props: {
        datos: {
            type: Object
        },
    },
    methods: {
    }
}
</script>