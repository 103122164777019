<template>
    <div class="row">
        <div class="col-md-5">
            <div class="input-group">                                                
                <input v-model="minimoselec" type="number" class="form-control form-control-sm" id="intervalo-minimo-coste" placeholder="Min">
                <div class="input-group-append">
                    <span class="input-group-text form-control-sm">&euro;</span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="input-group">                                                
                <input v-model="maximoselec" type="number" class="form-control form-control-sm" id="intervalo-maximo-coste" placeholder="Max">
                <div class="input-group-append">
                    <span class="input-group-text form-control-sm">&euro;</span>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <button @click="crearIntervalo()" class="btn btn-sm btn-primary">{{$t('general.anadir')}}</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <table class="table">
                <tbody>
                    <tr v-for="intervalo in intervalos" :key="intervalo.id_intervalo">
                        <td>{{ intervalo.minimo }}€-{{ intervalo.maximo }}€</td>
                        <td><button @click="eliminar(intervalo)" type="button" class="btn btn-block btn-outline-danger btn-xs">{{$t('general.eliminar')}}</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: {
        datos: {
            type: Object
        },
    },
    data(){
        return{
            intervalos:[],
            minimoselec:'',
            maximoselec:'',
        }
    },
    methods:{
        async crearIntervalo(){
            if(this.minimoselec == '' || this.maximoselec == ''){
                alert('Debe seleccionar un mínimo y un máximo');
                return;
            }else{
                const api = new PwgsApi();
                await api.post('companias/'+this.datos.idcompañia+'/intervalos',{minimo:this.minimoselec,maximo:this.maximoselec});
                this.cargarIntervalos();
            }
        },
        async cargarIntervalos(){
            const api = new PwgsApi();
            const resp = await api.get('companias/'+this.datos.idcompañia+'/intervalos');
            this.intervalos = resp.datos;
            if(!Array.isArray(this.intervalos))this.intervalos = [this.intervalos];
        },
        async eliminar(intervalo){
            const api = new PwgsApi();
            await api.delete('companias/'+this.datos.idcompañia+'/intervalos/'+intervalo.id_intervalo);
            this.cargarIntervalos();
        }
    },
    mounted(){
        this.cargarIntervalos();
    }
}
</script>