<template>
    <div class="card card-danger card-outline">
        <div class="card-body ">
            <div class="form-group">
                <div>
                    <label>{{$t('general.series')}}</label>
                </div>
                <div class="row">
                    
                    <table class="table table-sm table-striped">
                        <thead>
                            <tr >
                                <th>
                                    <select 
                        class="custom-select form-control-sm"
                        v-model="serieSeleccionada">
                            <option value="0">{{$t('general.seleccioneserie')}}</option>
                            <option 
                                v-for="serie in series"
                                :key="serie.id"
                                :value="serie.id"
                                >{{ serie.nombre_serie }}</option>
                        </select>
                                </th>
                                <th>
                                    <button class="btn btn-primary" @click="agregarSerie()">Añadir</button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="serie in seriesCompania" :key="serie.id_serie">
                                <td>{{ serie.nombre_serie }}</td>
                                <td><button class="btn btn-sm btn-danger" title="Eliminar" @click="eliminarSerie(serie.id_serie)"><i class="fas fa-trash-alt"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    props: {
        datos: {
            type: Object
        },
    },
    mounted(){
        this.obtenerSeries();
        this.obtenerSeriesCompania();
    },
    data() {
        return {
            serieSeleccionada: 0,
            series:[],
            seriesCompania:[],
        }
    },
    methods: {
        async obtenerSeries(){
            const api = new PwgsApi();
            const resp = await api.get('/lineas-facturacion');
            this.series = resp.datos;
        },
        async obtenerSeriesCompania(){
            const api = new PwgsApi();
            const resp = await api.get('companias/'+this.datos.idcompañia+'/lineas-facturacion');
            this.seriesCompania = resp.datos;
        },
        async eliminarSerie(id){
            const api = new PwgsApi();
            const resp = await api.delete('companias/'+this.datos.idcompañia+'/lineas-facturacion/'+id);
            console.log('resp',resp);
            this.obtenerSeriesCompania();
        },
        async agregarSerie(){
            if(this.serieSeleccionada==0){
                alert('selecciona  una serie');
            }else{
                const api = new PwgsApi();
                const body = {"id_linea":this.serieSeleccionada};
                const resp = await api.post('companias/'+this.datos.idcompañia+'/lineas-facturacion', body);
                console.log('resp',resp);
                this.obtenerSeriesCompania();
            }
        }
    }
}
</script>