<template>
    <seccion-datos 
        :titulo="subcuentas" 
        @cambios="guardar($data)" 
        tipo="success"
        >
        <div class="row"> 
            <div class="col-md-4">
                <div class="form-group">
                    <label for="subcuenta">{{$t('general.subcuenta')}}</label>
                    <input 
                        type="text" 
                        class="form-control form-control-sm" 
                        id="subcuenta" 
                        placeholder=""
                        v-model="codigo_cuenta"
                        >
                </div> 
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="subcuenta-ingresos">{{$t('general.subcuentaingresos')}}</label>
                    <input 
                        type="text" 
                        class="form-control form-control-sm" 
                        id="subcuenta-ingresos" 
                        placeholder=""
                        v-model="subcuenta_ingresos_companyia"
                        >
                </div>  
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="subcuenta-clientes-varios">{{$t('general.subcuentaclivarios')}}</label>
                    <input 
                        type="text" 
                        class="form-control form-control-sm" 
                        id="subcuenta-clientes-varios" 
                        placeholder=""
                        v-model="subcuenta_varios_companyia"
                        >
                </div> 
            </div>
        </div>
        <div class="row"> 
            <div class="col-md-4">
                <div class="form-group">
                    <label for="subcuenta-abono">{{ $t('general.subcuentaabono')}}</label>
                    <input 
                        type="text" 
                        class="form-control form-control-sm" 
                        id="subcuenta-abono" 
                        placeholder=""
                        v-model="subcuenta_abonos_companyia"
                        >
                </div>  
            </div>   
            <div class="col-md-4">
                <div class="form-group">
                    <label for="subcuenta-gasto">{{$t('general.subcuentagasto')}}</label>
                    <input 
                        type="text" 
                        class="form-control form-control-sm" 
                        id="subcuenta-gasto" 
                        placeholder=""
                        v-model="subcuenta_gasto_companyia"
                        >
                </div>  
            </div>                         
        </div>
    </seccion-datos>
</template>

<script>

export default {
    props: {
        datos: {
            type: Object
        },
    },
    inject: ['guardar'],
    data() {
        return  {
            codigo_cuenta: null,
            subcuenta_abonos_companyia: null,
            subcuenta_gasto_companyia: null,
            subcuenta_ingresos_companyia: null,
            subcuenta_varios_companyia: null,
            subcuentas:'Subcuentas'
        }
    },
    watch: {
        datos() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
        },
    },
    created() {
        this.init();
        this.subcuentas = this.$t('general.subcuenta') + "s";
    },
}
</script>